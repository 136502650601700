<template>
    <div class="row px-md-4">
      <div class="row">
        <div class="col-12 col-sm-12">
            <!--\
            <div class="mb-40px mt-14px">
            <router-link to="/settings" class="return-btn"
              ><i class="far fa-arrow-left"></i> Back to all settings</router-link
            >
            </div>
            -->
          <h2 class="main-title">
            Let Expertel iQ connect with your carrier
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div
            class="card p-60px"
            
            ref="card"
          >
            <div v-if="!formCompleted">
                <div>
                    <div class="info"><strong>Step 1:</strong> Please select your carrier</div>
                    <div class="holder_icon_carrier">
                        <div v-for="(item, index) in data_provider_list" :key="index" 
                            
                            @click="selectCarrier(item.id)"
                            
                            :class="[ credentials.carried_id === item.id ? 'column selected' : 'column']"
                        >
                        
                            <img
                                :src="`https://iq.expertel.ca/${item.logo}`"
                                class="img-fluid carrier-img"
                                
                            />

                            
                        </div>
                    </div>
                </div>
                <div>
                    <br><br>
                    <div class="info"><strong>Step 2:</strong> Login to your account</div>
                    <form id="form_login" @submit.prevent="validateForm()">
                        <div class="input-container">
                            <input 
                                type="text" 
                                value="" 
                                name="username" 
                                id="username"
                                v-model="credentials.username"
                                required
                                placeholder="Username"
                            >
                        </div>
                        <div class="input-container">
                            <input 
                                type="password" 
                                value="" 
                                name="password" 
                                id="password"
                                v-model="credentials.password"
                                required
                                placeholder="Password"
                            >
                            
                        </div>
                        
                        <div class="input-container text-right">
                            <div class="row" v-if="loading == true">
                                <div class="col-12 col-sm-12">
                                    <grid-loader
                                        class="expertel-loader"
                                        color="#ff502d"
                                        :loading="loading"
                                        :size="20"
                                        sizeUnit="px"
                                    >
                                    </grid-loader>
                                </div>
                            </div>
                            <!--
                            <button
                                class="btn btn-outline-expertel rounded-0  mr-3"
                            >
                                Cancel
                            </button> 
                            -->
                            <button
                                class="btn button-expertel rounded-0 "
                                
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                    <div class="holder_securtity_entry">
                        <img src="@/assets/icon-security.png" alt="Security" class="img-fluid" />
                        <span>Secure entry. Your credentials are NOT stored.</span>
                        
                    </div>
                </div>
            </div>
            
            <div v-if="formCompleted==1">
                <h1 class="main-title">
                    Thank you!
                </h1>
                <p class="msg-thanks">You will receive an email after we have synced with your carrier.</p>
                <div class="input-container text-right">

                    <button
                        class="btn button-expertel rounded-0 "
                        @click="logOut"
                    >
                        Logout
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  </template>
  
  <script>

  export default {
    props: ["login_data"],
    data() {
      return {
        //height_card: "",
        data_provider_list: [],
        credentials: {
            username: "",
            password: "",
            carried_id: ""
        },
        formCompleted: "",
        loading: false,
        formMessage: "",
      };
    },
    created() {
      if (this.login_data !== undefined || this.login_data.success === false) {
        if (!this.login_data.success)
        {
          this.$router.push("/");
        } 
        else 
        {
            //=======================================
            //Get the priver list
            //=======================================
            this.getCarrierList();
        }
      } else {
        this.$router.push("/");
      }
    },
    methods: {
        getCarrierList() {
            const self = this;
            this.loading = true;
            this.axios({
                method: "get",
                url: `https://api.expertel.ca/api/v1/providers`,
                responseType: "json",
                headers: {
                Authorization: "Bearer " + self.login_data.token
                }
            })
            .then(function(response) {
                self.data_provider_list = response.data
                self.loading = false;
            })
            .catch(error => this.makeToast("ERROR", error, "danger"));
        },

        validateForm(){
            const self = this;
            let formData = new FormData();
            if (self.credentials.username === "") {
                self.makeToast(
                    "ERROR",
                    "The new username is empty",
                    "danger"
                );
                return false;
            }
            formData.append("provider_username", self.credentials.username);

            if (self.credentials.password === "") {
                self.makeToast(
                    "ERROR",
                    "The new password is empty",
                    "danger"
                );
                return false;
            }
            formData.append("provider_password", self.credentials.password);

            if (self.credentials.carried_id === "") {
                self.makeToast(
                    "ERROR",
                    "Please select your carrier",
                    "danger"
                );
                return false;
            }

            formData.append("provider_id", self.credentials.carried_id);

            
            this.loading = true;
            //return false;
            this.axios({
                method: "post",
                url: `https://api.expertel.ca/api/v1/client/me/accounts`,
                responseType: "json",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + self.login_data.token
                }
            })
            .then(function(response) {
                console.log(response.data);
                if (response.data.success === false) {
                    self.loading = false;
                    self.formMessage = response.data.message;
                    self.makeToast("ERROR", self.formMessage, "danger")
                    
                } else {
                    self.loading = false;
                    self.formMessage = "The information has been successfully saved.";
                    self.makeToast("SUCCESS", self.formMessage, "success")
                    self.formCompleted = 1
                }

                
            })
            .catch(error => (self.formMessage = error));
        },

        makeToast(title, message, variant = null) {
            this.$bvToast.toast(`${message}`, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true,
                variant: variant
            });
        },

        selectCarrier(id){
            this.credentials.carried_id = id
        },

        logOut(){
            this.$emit("setLogout");
        }

    }
    /*
    afterMounted() {
      this.calculateHeight();
    },
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    }
    */
  };
  </script>

<style lang="scss">
        /* Enter and leave animations can use different */
        /* durations and timing functions.              */
    .info {
        color: #878ea0;
        font-size: 16px;
    }

    .msg-thanks{
        color: #6f7479;
        font-size: 18px;
    }

    .holder_securtity_entry{
        padding: 15px;
        background: #eef2f8;
        border: solid 1px #cbdaf1;
        color: #56657b;
        
        letter-spacing: 0.7px;
        margin-top: 25px;
    }

    .holder_securtity_entry span{
        font-weight: 700;
        font-size: 16px;
    }

    .holder_securtity_entry img{
        max-width: 32px;
        height: auto;
        margin-right: 15px;
    }

    .holder_icon_carrier{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 0px;
        background-color: #fff;
        color: #444;
        align-content: center;
    }
    .holder_icon_carrier .column{
        
        border: solid 1px #ccc ;
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        justify-content: center;
        padding: 15px 10px;
        cursor: pointer;
    }

    .holder_icon_carrier .column.selected,
    .holder_icon_carrier .column:hover{
        background: #e9e9e9;
    }
    
    .holder_icon_carrier img{
        max-width: 168px;
        width: 100%;
        height: auto;
    }

    #form_login input[type=text],
    #form_login input[type=password]{
        border: solid 1px #ccc ;
        padding: 15px;
        width: 100%;
    }

    #form_login .input-container{
        margin-bottom: 20px;
    }

    #form_login .input-container:first-of-type{
        margin-top: 20px;
    }

    
    #item_my_account,
    .sidebar-primary {
        width: 0px;
        display: none !important;
    }
    

    @media (max-width: 1100px){
        .holder_icon_carrier img{
            max-width: 120px;
            height: auto;
        }
    }

    @media (max-width: 768px){
        .holder_icon_carrier img{
            max-width: 168px;
            height: auto;
        }
        
        .holder_icon_carrier{
            grid-template-columns: repeat(2, 1fr);
        }
    }
</style>
  